import React from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import config from '../../config';
import './PageFooterLayout.css';
import Link from '../../components/common/Link';
import APIcon from 'apex-web/lib/components/common/APIcon';

const pageFooterClasses = getBEMClasses('page-footer');

const PageFooterLayout = (props, context) => {
  return (
    <footer className={pageFooterClasses()}>
      <div className={pageFooterClasses('footer-left-content')}>
        {context.t('{n}™ {y}. All Rights Reserved.', {
          n: process.env.REACT_APP_SITE_NAME,
          y: new Date().getFullYear()
        })}
      </div>
      <div className={pageFooterClasses('footer-right-content')}>
        {config.Footer.links.map((column, idx) => {
          return (
            <div key={idx} className={pageFooterClasses('text')}>
              {column.map((item, innerIdx) => {
                let openInNewWindow =
                  config.Footer.openInNewWindowGlobal || true;

                if ('openInNewWindow' in item) {
                  openInNewWindow = item.openInNewWindow;
                }

                return (
                  <Link
                    key={'' + idx + innerIdx}
                    target={openInNewWindow ? '_blank' : ''}
                    rel={openInNewWindow ? 'noopener noreferrer' : ''}
                    href={item.link}>
                    {context.t(item.text)}
                  </Link>
                );
              })}
            </div>
          );
        })}
        <div className={pageFooterClasses('icon')}>
          {!config.Footer.powerOff && <APIcon name="logo-powered" />}
        </div>
      </div>
    </footer>
  );
};

PageFooterLayout.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PageFooterLayout;
