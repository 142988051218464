import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APLogo from 'apex-web/lib/components/common/APLogo/APLogo';
import PageHeaderNavComponent from '../../components/PageHeader/PageHeaderNavComponent';
import UserSummaryContainer from '../../components/UserSummary/UserSummaryContainer';
import './PageHeaderLayout.css';
import './UserSummaryOverrides.css';
import PropTypes from 'prop-types';
import Link from '../../components/common/Link';
import APIcon from 'apex-web/lib/components/common/APIcon';

const pageHeaderClasses = getBEMClasses('page-header');

const MainNavItems = [
  { text: 'Dashboard', path: '/dashboard' },
  {
    text: 'Trade',
    icon: 'trading',
    path: '#',
    submenuNavItems: [
      /* {
        text: 'Buy & Sell',
        subtitle: 'Simple Swaps',
        icon: 'buy-sell',
        path: '/buy-sell'
      }, */
      {
        text: 'Spot & Margin',
        subtitle: 'Advanced Trading',
        icon: 'trading',
        path: '/exchange'
      },
      { text: 'Futures', subtitle: 'Coming Soon', icon: 'futures', path: '#' },
      {
        text: 'Stream',
        subtitle: 'Multi Pair',
        icon: 'streaming',
        path: '/eotc'
      }
    ]
  },
  {
    text: 'Yield',
    icon: 'trading',
    path: '#',
    submenuNavItems: [
      {
        text: 'Open Term',
        subtitle: 'Flexible Earnings',
        icon: 'open-term',
        path: '/iba'
      },
      {
        text: 'Fixed Term',
        subtitle: 'Coming Soon',
        icon: 'fixed-term',
        path: '#'
      }
    ]
  },
  { text: 'Digital Assets', path: '/digital-assets' },
  {
    text: 'Wallet',
    icon: 'wallet',
    path: '#',
    submenuNavItems: [
      {
        text: 'Wallets',
        subtitle: 'Deposit and Withdraw',
        icon: 'wallet',
        path: '/wallets'
      },
      {
        text: 'Balances',
        subtitle: 'Assets Across Platform',
        icon: 'balance',
        path: '/margin-balances'
      }
    ]
  }

  // { text: 'Activity', icon: 'activity', path: '/asset-activity-details' }
  // { text: 'Components', icon: 'star', path: '/component' }
];

const PageHeaderComponent = (props, context) => {
  const { isAuthenticated } = props;

  return (
    <React.Fragment>
      <div className={pageHeaderClasses('container')}>
        <div className={pageHeaderClasses('content-wrapper')}>
          <div className={pageHeaderClasses('header-item', 'logo')}>
            <APLogo customClass={pageHeaderClasses('logo')} linkTo="/" />
          </div>
          <PageHeaderNavComponent navItems={MainNavItems} />
          <div className={pageHeaderClasses('right-panel')}>
            {isAuthenticated ? (
              <UserSummaryContainer
                customClass="page-header-user-summary"
                settingsRoute="/settings/user"
              />
            ) : (
              <div className={pageHeaderClasses('login-content-wrapper')}>
                <Link
                  href={'/login'}
                  className={pageHeaderClasses('login-link')}>
                  <span className="text">{context.t('Login')}</span>
                  <span className="icon">
                    <svg
                      width="19"
                      height="7"
                      viewBox="0 0 19 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.4049 3.78168V6.5403L18.2874 3.32191L14.4049 0.103516V2.86214H0.816089V3.78168H14.4049Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PageHeaderComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated
});

export default withRouter(connect(mapStateToProps)(PageHeaderComponent));
