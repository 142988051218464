import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import APIcon from 'apex-web/lib/components/common/APIcon';

class PageHeaderNavMenuComponent extends React.Component {
  submenuNavItemsInitialState = {
    Trade: false,
    Yield: false,
    Wallet: false
  };

  state = this.submenuNavItemsInitialState;

  handleNavItemClick = navItemText => {
    const isSubmenuNavItem =
      this.submenuNavItemsInitialState[navItemText] !== undefined;

    this.props.handleNavMenuClick(isSubmenuNavItem);

    this.setState(prevState => ({
      ...this.submenuNavItemsInitialState,
      [navItemText]: !prevState[navItemText]
    }));
  };

  render() {
    const { navItems, customClass } = this.props;

    return navItems.map(item => {
      return (
        <div
          key={item.text}
          className={customClass(
            `nav-item ${item.submenuNavItems ? 'sub-menu' : ''}`,
            ['hoverable', `${this.state[item.text] ? 'opened' : ''}`]
          )}>
          <NavLink
            to={item.path}
            onClick={() => this.handleNavItemClick(item.text)}
            isActive={match => match && item.path !== '#'}
            className={customClass('item')}
            activeClassName={customClass('item', ['selected'])}>
            <span
              className={customClass(
                `label ${item.submenuNavItems ? 'submenu-label' : ''}`
              )}>
              {this.context.t(item.text)}
            </span>
          </NavLink>
          {item.submenuNavItems && (
            <div className={customClass('sub-menu-item')}>
              <div className="bg-nav-item" />
              <div className={customClass('sub-menu-body')}>
                <ul className={customClass('sub-menu-nav-list')}>
                  {item.submenuNavItems.map(submenuItem => (
                    <li
                      key={submenuItem.path}
                      className={customClass('sub-menu-nav-list-item')}>
                      <NavLink
                        to={submenuItem.path}
                        onClick={() =>
                          this.handleNavItemClick(submenuItem.text)
                        }
                        isActive={match => match && item.path !== '#'}
                        className={customClass('item', [
                          'sub-menu',
                          'hoverable'
                        ])}
                        activeClassName={customClass('item', ['selected'])}>
                        <APIcon
                          name={submenuItem.icon}
                          customClass={customClass('icon')}
                        />
                        <div className={customClass('label-wrapper')}>
                          <span className={customClass('label')}>
                            {this.context.t(submenuItem.text)}
                          </span>
                          <span className={customClass('subtitle')}>
                            {this.context.t(submenuItem.subtitle)}
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      );
    });
  }
}

PageHeaderNavMenuComponent.propTypes = {
  customClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  items: PropTypes.array
};

PageHeaderNavMenuComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PageHeaderNavMenuComponent;
