import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserSummary from './UserSummaryComponent';
import { logout } from 'apex-web/lib/redux/actions/authActions';
import { selectAccount } from 'apex-web/lib/redux/actions/userActions';

const mapStateToProps = state => {
  const { selectedAccountId } = state.user;
  const accounts = state.user.accounts;
  let selectedAccount = accounts.find(
    account => account.AccountId === selectedAccountId
  );

  return {
    betaOptedIn: localStorage.getItem('betaOptedIn'),
    accounts,
    selectedAccount
  };
};

const mapDispatchToProps = {
  logout,
  selectAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserSummary));
